import React from 'react'
import { Link } from "gatsby"

import SEO from '../components/seo'
import { Container, Row, Col, Breadcrumb } from 'react-bootstrap';
import Layout from '../components/layout'

import HeroPage from '../components/views/hero-page'

export default () => {
  return (
    <Layout>
      <SEO title="Абишийн Мекей багшийн тухай" img={`/img/s3.jpg`} />
      <HeroPage
        title="Абишийн Мекей багшийн тухай"
        img="s3.jpg"
      />
        <div className="page-container">
            <Container>
                <Row>
                    <Col>
                    <Breadcrumb>
                      <li className="breadcrumb-item">
                        <Link to="/">Нүүр</Link>
                      </li>
                      <Breadcrumb.Item active>Абишийн Мекей багшийн тухай</Breadcrumb.Item>
                    </Breadcrumb>
                      <div>
                        А.Мекей багш бол 1940 онд Баян-Өлгий аймгийн Толбонуур сумын нутагт төрсөн, 1949-1959 онд Баян-Өлгий аймгийн 1-р арван жилийн дунд сургууль, 1959-1963 онд МУИС-ийн математикийн салбарыг төгсөж 1963 оноос МУИС-д ажилласан МУИС-ийн шилдэг төгсөгч, багш, эрдэмтэн, сурган хүмүүжүүлэгч, удирдагч байсан билээ.<br /><br />
                        Тэрээр 1972 онд Москвагийн Улсын Их Сургууль (МосУИС)-ийн Механик-математикийн факультетад Физик-Математикийн ухааны дэд эрдэмтэн, 1995 онд Шинжлэх ухааны докторын зэрэг хамгаалсан. <br /><br />
                        А.Мекей багшийн МУИС болон Монгол улсын боловсрол, шинжлэх ухааны салбарт оруулсан хувь нэмэр, хүчин зүтгэлийг өндрөөр үнэлж 1981 онд дэд профессор, 1992 онд профессор, 2004 онд Монгол улсын ШУА-ийн академич, Монгол улсын гавъяат багш, 2012 онд Монгол улсын Ардын багш цол олгожээ.<br /><br />
                        А.Мекей багш 1963-1978 онд МУИС-д багш, 1978-1989 онд МУИС-ийн Физик Математикийн Факультетийн декан, 1990-1997 онд МУИС-ийн эрдэм шинжилгээ гадаад харилцаа эрхэлсэн дэд захирал, 1999-2008 онд магистр докторын сургалтын албаны эрхлэгч, 2000-2011 онд Математикийн хүрээлэнгийн захирал, 2011 оноос тус хүрээлэнгийн Алгебрийн секторын эрхлэгч, МУИС-ийн зөвлөх профессороор ажиллаж МУИС болон Монгол улсын боловсрол, математикийн шинжлэх ухаанд өөрийн бүхий л ажил, амьдралаа зориулсан нөр их хөдөлмөрч, үлгэр дууриал болсон хойч үеийн бид бүхний бахархан дуурайх хүндэт багш байсан юм.<br /><br />
                        Академич А.Мекей Монгол улсад Алгебрын чиглэлээр онолын судалгааны шавь сургуулийг үүсгэн хөгжүүлж, шавь нараас нь 2 академич, 2 шинжлэх ухааны доктор, 8 дэд доктор, 20 гаруй нь магистрын зэрэг хамгаалжээ. <br /><br />
                        1972 оноос түүний санаачилгаар үүссэн “Алгебр ба Цагиргийн онол” сэдэвт эрдэм шинжилгээний семинар одоо хүртэл уламжлагдан үргэлжилж байна.<br /><br />
                        Академич А.Мекей 1963 оноос хойш насан эцэслэтлээ багшлахдаа МУИС дээр Математик логик, Ли алгебр, Категорийн онол, Цагирагийн онол, Ерөнхий алгебр, Топологи ба алгебрын системүүд, Компьюторон алгебр, Криптограф, Элементар математик зэрэг олон хичээлийн хөтөлбөр анхлан боловсруулж, заасан юм.<br /><br />
                        Академич А.Мекейгийн судалгааны олон тооны бүтээл дотроос төгсгөлөг дөхөгдөх цогцыг тоймлол, цагирагийн онолд цогцын шулуун нэгдлийн ойлголтыг тодорхойлсон, критик цагиргийн нэгэн ангийг нээсэн зэргийг тухайн чиглэлээр ажилладаг Зөвлөлт, Оросын нэрт математикчид болон Москвагийн УИС-ийн алгебрын тэнхимийн эрдэмтэд өндрөөр үнэлж, Критик цагирагуудыг тоймлосон бүтээл нь Математикийн шинжлэх ухаанд шинэ нээлт гэж үзсэн байдаг.<br /><br />
                        Түүний хийсэн судалгааны ажлын үр дүнг 2012 онд барууны шилдэг математикчид “Мекейгийн бодлого” нэрээр алдаршуулжээ.<br /><br />
                        Академич А.Мекей МУИС-ийн удирдлагын багт ажиллаж байх хугацаандаа сургалт, эрдэм шинжилгээний ажлыг чанаржуулах, сайжруулахтай холбоотой олон ажлыг санаачилж ажилласан.<br /><br />
                        Академич А.Мекей Математикийн хүрээлэнгийн захиралаар ажиллаж байх хугацаандаа тус хүрээлэнгийн судалгааны ажлыг өргөжүүлж өнөөгийн түвшинд хүргэхэд жинтэй хувь нэмэр оруулсан юм.<br /><br />
                        Монголын математикийн нийгэмлэгийн ерөнхийлөгчөөр 2003 оноос хойш ажиллах хугацаанд “Mongolian Mathematical Journal” эрдэм шинжилгээний сэтгүүлийг хэвлэн гаргах ажлыг тогтмолжуулан сайжруулж, олон улсад хүлээн зөвшөөрөгдсөн сэтгүүл болох ажлын үндсийг тавьсан болохыг мэргэжил нэгт, салбарын хүмүүс мэддэг ч, хойч үе маань сайн мэддэггүй.<br /><br />
                        Академич А.Мекей нь нийт 150 гаруй бүтээл туурвиснаас 30 орчим ном, сурах бичиг, гарын авлага, 50 гаруй эрдэм шинжилгээний өгүүлэл нийтлүүлсэн.<br /><br />
                        Тэрээр 1963-1985 онд Улсын математикийн олимпиадын зохион байгуулах хорооны гишүүн, 1985-1990 онд хорооны дарга, олон улсын математикийн олимпиадын шүүгчдийн хорооны гишүүнээр ажиллажээ. <br /><br />
                        Түүний зохиож дэвшүүлсэн бодлого Румын улсад зохион байгуулагдсан Олон Улсын Математикийн Олимпиадад сонгогдож байлаа. 1985 оноос дунд сургуулийн сурагчдын олимпиадад бэлтгэгчдэд зориулсан гарын авлага бэлтгэх ажлыг анхлан санаачлан, хэвлэх ажлыг эхлүүлж байсан нь одоо уламжлал болжээ.<br /><br />
                        Ардын багш А.Мекей Монголын математик боловсролын үзэл баримтлал, стандарт, сургалтын хөтөлбөрийн шинэчлэлд тэргүүлэн оролцож, олон сурах бичгийн зохиогч, редактораар ажиллаж Монгол улсын дээд боловсролын төдийгүй ерөнхий боловсролын хөгжилд жинтэй хувь нэмэр оруулсан. <br /><br />
                        Мөн 12 жилийн боловсролын агуулга стандартаар бичсэн I, II ангийн математикийн сурах бичгийг казах хэл дээр орчуулсан.<br /><br />
                        Тэрээр 1984 онд их дээд сургуульд элсэгчдэд зориулж нөхдийн хамт зохион бичсэн “Математик” ном нь 2000 он хүртэл 10-р анги төгсөх бүх сурагчдын дунд “1000 бодлогын хураамж” нэртэйгээр алдаршсан гарын авлага билээ.
                      </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </Layout>
  )
}
